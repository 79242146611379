export const CommonAppDirect = () => import('../../components/common/AppDirect.vue' /* webpackChunkName: "components/common-app-direct" */).then(c => wrapFunctional(c.default || c))
export const CommonAppDirectIcon = () => import('../../components/common/AppDirectIcon.vue' /* webpackChunkName: "components/common-app-direct-icon" */).then(c => wrapFunctional(c.default || c))
export const CommonAppDirectQR = () => import('../../components/common/AppDirectQR.vue' /* webpackChunkName: "components/common-app-direct-q-r" */).then(c => wrapFunctional(c.default || c))
export const CommonFooter = () => import('../../components/common/Footer.vue' /* webpackChunkName: "components/common-footer" */).then(c => wrapFunctional(c.default || c))
export const CommonGAContainer = () => import('../../components/common/GAContainer.vue' /* webpackChunkName: "components/common-g-a-container" */).then(c => wrapFunctional(c.default || c))
export const CommonGoTop = () => import('../../components/common/GoTop.vue' /* webpackChunkName: "components/common-go-top" */).then(c => wrapFunctional(c.default || c))
export const CommonHeader = () => import('../../components/common/Header.vue' /* webpackChunkName: "components/common-header" */).then(c => wrapFunctional(c.default || c))
export const CommonIndexTopFalcon = () => import('../../components/common/IndexTopFalcon.vue' /* webpackChunkName: "components/common-index-top-falcon" */).then(c => wrapFunctional(c.default || c))
export const CommonSeoContainer = () => import('../../components/common/SeoContainer.vue' /* webpackChunkName: "components/common-seo-container" */).then(c => wrapFunctional(c.default || c))
export const EventPixstarContainer = () => import('../../components/event/PixstarContainer.vue' /* webpackChunkName: "components/event-pixstar-container" */).then(c => wrapFunctional(c.default || c))
export const V3CouponContainer = () => import('../../components/v3/CouponContainer.vue' /* webpackChunkName: "components/v3-coupon-container" */).then(c => wrapFunctional(c.default || c))
export const V3EventLink = () => import('../../components/v3/EventLink.vue' /* webpackChunkName: "components/v3-event-link" */).then(c => wrapFunctional(c.default || c))
export const V3HeaderV3 = () => import('../../components/v3/HeaderV3.vue' /* webpackChunkName: "components/v3-header-v3" */).then(c => wrapFunctional(c.default || c))
export const V3HotArticleContainer = () => import('../../components/v3/HotArticleContainer.vue' /* webpackChunkName: "components/v3-hot-article-container" */).then(c => wrapFunctional(c.default || c))
export const V3RecommendContainer = () => import('../../components/v3/RecommendContainer.vue' /* webpackChunkName: "components/v3-recommend-container" */).then(c => wrapFunctional(c.default || c))
export const V3RecommendSelect = () => import('../../components/v3/RecommendSelect.vue' /* webpackChunkName: "components/v3-recommend-select" */).then(c => wrapFunctional(c.default || c))
export const V3SectionTitle = () => import('../../components/v3/SectionTitle.vue' /* webpackChunkName: "components/v3-section-title" */).then(c => wrapFunctional(c.default || c))
export const V3TaskContainer = () => import('../../components/v3/TaskContainer.vue' /* webpackChunkName: "components/v3-task-container" */).then(c => wrapFunctional(c.default || c))
export const V3TrialReview = () => import('../../components/v3/TrialReview.vue' /* webpackChunkName: "components/v3-trial-review" */).then(c => wrapFunctional(c.default || c))
export const V3VideoContainer = () => import('../../components/v3/VideoContainer.vue' /* webpackChunkName: "components/v3-video-container" */).then(c => wrapFunctional(c.default || c))
export const ChildAdvertiseContainer = () => import('../../components/child/advertise/AdvertiseContainer.vue' /* webpackChunkName: "components/child-advertise-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleAdultThemeArticleContainer = () => import('../../components/child/article/AdultThemeArticleContainer.vue' /* webpackChunkName: "components/child-article-adult-theme-article-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleAdultChannelContainer = () => import('../../components/child/article/ArticleAdultChannelContainer.vue' /* webpackChunkName: "components/child-article-adult-channel-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleAdultHotContainer = () => import('../../components/child/article/ArticleAdultHotContainer.vue' /* webpackChunkName: "components/child-article-adult-hot-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleCard = () => import('../../components/child/article/ArticleCard.vue' /* webpackChunkName: "components/child-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleCardAd = () => import('../../components/child/article/ArticleCardAd.vue' /* webpackChunkName: "components/child-article-card-ad" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleCardStyleme = () => import('../../components/child/article/ArticleCardStyleme.vue' /* webpackChunkName: "components/child-article-card-styleme" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleChannelContainer = () => import('../../components/child/article/ArticleChannelContainer.vue' /* webpackChunkName: "components/child-article-channel-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleContainer = () => import('../../components/child/article/ArticleContainer.vue' /* webpackChunkName: "components/child-article-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotBeautyCard = () => import('../../components/child/article/ArticleHotBeautyCard.vue' /* webpackChunkName: "components/child-article-hot-beauty-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotBeautyContainer = () => import('../../components/child/article/ArticleHotBeautyContainer.vue' /* webpackChunkName: "components/child-article-hot-beauty-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotContainer = () => import('../../components/child/article/ArticleHotContainer.vue' /* webpackChunkName: "components/child-article-hot-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotIndexCard = () => import('../../components/child/article/ArticleHotIndexCard.vue' /* webpackChunkName: "components/child-article-hot-index-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotIndexContainer = () => import('../../components/child/article/ArticleHotIndexContainer.vue' /* webpackChunkName: "components/child-article-hot-index-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotIndexDesktopCard = () => import('../../components/child/article/ArticleHotIndexDesktopCard.vue' /* webpackChunkName: "components/child-article-hot-index-desktop-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleListCardContainer = () => import('../../components/child/article/ArticleListCardContainer.vue' /* webpackChunkName: "components/child-article-list-card-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleCollectionCard = () => import('../../components/child/article/CollectionCard.vue' /* webpackChunkName: "components/child-article-collection-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleCollectionContainer = () => import('../../components/child/article/CollectionContainer.vue' /* webpackChunkName: "components/child-article-collection-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotArticleCard = () => import('../../components/child/article/HotArticleCard.vue' /* webpackChunkName: "components/child-article-hot-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotArticleCardAdult = () => import('../../components/child/article/HotArticleCardAdult.vue' /* webpackChunkName: "components/child-article-hot-article-card-adult" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotArticleCardAdultAd = () => import('../../components/child/article/HotArticleCardAdultAd.vue' /* webpackChunkName: "components/child-article-hot-article-card-adult-ad" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleHotArticleCardAdultMedia = () => import('../../components/child/article/HotArticleCardAdultMedia.vue' /* webpackChunkName: "components/child-article-hot-article-card-adult-media" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleMixArticleContainer = () => import('../../components/child/article/MixArticleContainer.vue' /* webpackChunkName: "components/child-article-mix-article-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleMixArticleLoading = () => import('../../components/child/article/MixArticleLoading.vue' /* webpackChunkName: "components/child-article-mix-article-loading" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleNewArticleCard = () => import('../../components/child/article/NewArticleCard.vue' /* webpackChunkName: "components/child-article-new-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticlePcardArticleCard = () => import('../../components/child/article/PcardArticleCard.vue' /* webpackChunkName: "components/child-article-pcard-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticlePcardCollectCard = () => import('../../components/child/article/PcardCollectCard.vue' /* webpackChunkName: "components/child-article-pcard-collect-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticlePcardShortArticleCard = () => import('../../components/child/article/PcardShortArticleCard.vue' /* webpackChunkName: "components/child-article-pcard-short-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleSelfArticleCard = () => import('../../components/child/article/SelfArticleCard.vue' /* webpackChunkName: "components/child-article-self-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleShortArticleCard = () => import('../../components/child/article/ShortArticleCard.vue' /* webpackChunkName: "components/child-article-short-article-card" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleShortArticleContainer = () => import('../../components/child/article/ShortArticleContainer.vue' /* webpackChunkName: "components/child-article-short-article-container" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleStyleMeArticleContainer = () => import('../../components/child/article/StyleMeArticleContainer.vue' /* webpackChunkName: "components/child-article-style-me-article-container" */).then(c => wrapFunctional(c.default || c))
export const ChildBannerCard = () => import('../../components/child/banner/BannerCard.vue' /* webpackChunkName: "components/child-banner-card" */).then(c => wrapFunctional(c.default || c))
export const ChildBannerContainer = () => import('../../components/child/banner/BannerContainer.vue' /* webpackChunkName: "components/child-banner-container" */).then(c => wrapFunctional(c.default || c))
export const ChildBloggerCard = () => import('../../components/child/blogger/BloggerCard.vue' /* webpackChunkName: "components/child-blogger-card" */).then(c => wrapFunctional(c.default || c))
export const ChildBloggerContainer = () => import('../../components/child/blogger/BloggerContainer.vue' /* webpackChunkName: "components/child-blogger-container" */).then(c => wrapFunctional(c.default || c))
export const ChildBloggerLoginContainer = () => import('../../components/child/blogger/BloggerLoginContainer.vue' /* webpackChunkName: "components/child-blogger-login-container" */).then(c => wrapFunctional(c.default || c))
export const ChildBloggerRanking = () => import('../../components/child/blogger/BloggerRanking.vue' /* webpackChunkName: "components/child-blogger-ranking" */).then(c => wrapFunctional(c.default || c))
export const ChildBloggerRankingChannel = () => import('../../components/child/blogger/BloggerRankingChannel.vue' /* webpackChunkName: "components/child-blogger-ranking-channel" */).then(c => wrapFunctional(c.default || c))
export const ChildBloggerRankingChannelAdult = () => import('../../components/child/blogger/BloggerRankingChannelAdult.vue' /* webpackChunkName: "components/child-blogger-ranking-channel-adult" */).then(c => wrapFunctional(c.default || c))
export const ChildBrowseContainer = () => import('../../components/child/browse/BrowseContainer.vue' /* webpackChunkName: "components/child-browse-container" */).then(c => wrapFunctional(c.default || c))
export const ChildIndexTabContainer = () => import('../../components/child/index_tab/IndexTabContainer.vue' /* webpackChunkName: "components/child-index-tab-container" */).then(c => wrapFunctional(c.default || c))
export const ChildIndexTabNewIndexTabContainer = () => import('../../components/child/index_tab/NewIndexTabContainer.vue' /* webpackChunkName: "components/child-index-tab-new-index-tab-container" */).then(c => wrapFunctional(c.default || c))
export const ChildLightboxToastContainer = () => import('../../components/child/lightbox/ToastContainer.vue' /* webpackChunkName: "components/child-lightbox-toast-container" */).then(c => wrapFunctional(c.default || c))
export const ChildLightboxToastShortContainer = () => import('../../components/child/lightbox/ToastShortContainer.vue' /* webpackChunkName: "components/child-lightbox-toast-short-container" */).then(c => wrapFunctional(c.default || c))
export const ChildMarqeenContainer = () => import('../../components/child/marqeen/MarqeenContainer.vue' /* webpackChunkName: "components/child-marqeen-container" */).then(c => wrapFunctional(c.default || c))
export const ChildMarqeenContent = () => import('../../components/child/marqeen/MarqeenContent.vue' /* webpackChunkName: "components/child-marqeen-content" */).then(c => wrapFunctional(c.default || c))
export const ChildMoviePopularMoive = () => import('../../components/child/movie/PopularMoive.vue' /* webpackChunkName: "components/child-movie-popular-moive" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardBlockMember = () => import('../../components/child/pcard/BlockMember.vue' /* webpackChunkName: "components/child-pcard-block-member" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardCheckToUnBlockMember = () => import('../../components/child/pcard/CheckToUnBlockMember.vue' /* webpackChunkName: "components/child-pcard-check-to-un-block-member" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardCheckToUnFollow = () => import('../../components/child/pcard/CheckToUnFollow.vue' /* webpackChunkName: "components/child-pcard-check-to-un-follow" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardExecuteBlog = () => import('../../components/child/pcard/ExecuteBlog.vue' /* webpackChunkName: "components/child-pcard-execute-blog" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardExecuteShortArticle = () => import('../../components/child/pcard/ExecuteShortArticle.vue' /* webpackChunkName: "components/child-pcard-execute-short-article" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardIsBlockMember = () => import('../../components/child/pcard/IsBlockMember.vue' /* webpackChunkName: "components/child-pcard-is-block-member" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardMemberReport = () => import('../../components/child/pcard/MemberReport.vue' /* webpackChunkName: "components/child-pcard-member-report" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardNoArticleStatus = () => import('../../components/child/pcard/NoArticleStatus.vue' /* webpackChunkName: "components/child-pcard-no-article-status" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardNoRelationStatus = () => import('../../components/child/pcard/NoRelationStatus.vue' /* webpackChunkName: "components/child-pcard-no-relation-status" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardUserPointRank = () => import('../../components/child/pcard/UserPointRank.vue' /* webpackChunkName: "components/child-pcard-user-point-rank" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardBlockCard = () => import('../../components/child/pcard/pcardBlockCard.vue' /* webpackChunkName: "components/child-pcard-block-card" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardFollowCard = () => import('../../components/child/pcard/pcardFollowCard.vue' /* webpackChunkName: "components/child-pcard-follow-card" */).then(c => wrapFunctional(c.default || c))
export const ChildPopularityCard = () => import('../../components/child/popularity/PopularityCard.vue' /* webpackChunkName: "components/child-popularity-card" */).then(c => wrapFunctional(c.default || c))
export const ChildPopularityContainer = () => import('../../components/child/popularity/PopularityContainer.vue' /* webpackChunkName: "components/child-popularity-container" */).then(c => wrapFunctional(c.default || c))
export const ChildPostAndEditShortArticleModule = () => import('../../components/child/post/PostAndEditShortArticleModule.vue' /* webpackChunkName: "components/child-post-and-edit-short-article-module" */).then(c => wrapFunctional(c.default || c))
export const ChildPostShortArticle = () => import('../../components/child/post/PostShortArticle.vue' /* webpackChunkName: "components/child-post-short-article" */).then(c => wrapFunctional(c.default || c))
export const ChildProductInfo = () => import('../../components/child/product/ProductInfo.vue' /* webpackChunkName: "components/child-product-info" */).then(c => wrapFunctional(c.default || c))
export const ChildProductRanking = () => import('../../components/child/product/ProductRanking.vue' /* webpackChunkName: "components/child-product-ranking" */).then(c => wrapFunctional(c.default || c))
export const ChildRecipeCard = () => import('../../components/child/recipe/RecipeCard.vue' /* webpackChunkName: "components/child-recipe-card" */).then(c => wrapFunctional(c.default || c))
export const ChildRecipeContainer = () => import('../../components/child/recipe/RecipeContainer.vue' /* webpackChunkName: "components/child-recipe-container" */).then(c => wrapFunctional(c.default || c))
export const ChildTagAllSiteContainer = () => import('../../components/child/tag/TagAllSiteContainer.vue' /* webpackChunkName: "components/child-tag-all-site-container" */).then(c => wrapFunctional(c.default || c))
export const ChildTagCapsule = () => import('../../components/child/tag/TagCapsule.vue' /* webpackChunkName: "components/child-tag-capsule" */).then(c => wrapFunctional(c.default || c))
export const ChildTagContainer = () => import('../../components/child/tag/TagContainer.vue' /* webpackChunkName: "components/child-tag-container" */).then(c => wrapFunctional(c.default || c))
export const ChildTagIndexContainer = () => import('../../components/child/tag/TagIndexContainer.vue' /* webpackChunkName: "components/child-tag-index-container" */).then(c => wrapFunctional(c.default || c))
export const ChildTagSearch = () => import('../../components/child/tag/TagSearch.vue' /* webpackChunkName: "components/child-tag-search" */).then(c => wrapFunctional(c.default || c))
export const ChildTextBubbleContainer = () => import('../../components/child/text_bubble/TextBubbleContainer.vue' /* webpackChunkName: "components/child-text-bubble-container" */).then(c => wrapFunctional(c.default || c))
export const ChildVideoIndexContainer = () => import('../../components/child/video/VideoIndexContainer.vue' /* webpackChunkName: "components/child-video-index-container" */).then(c => wrapFunctional(c.default || c))
export const ChildVideoWallCard = () => import('../../components/child/video/VideoWallCard.vue' /* webpackChunkName: "components/child-video-wall-card" */).then(c => wrapFunctional(c.default || c))
export const ChildVideoWallContainer = () => import('../../components/child/video/VideoWallContainer.vue' /* webpackChunkName: "components/child-video-wall-container" */).then(c => wrapFunctional(c.default || c))
export const CommonNotificationPanel = () => import('../../components/common/notification/Panel.vue' /* webpackChunkName: "components/common-notification-panel" */).then(c => wrapFunctional(c.default || c))
export const CommonNotificationPanelCard = () => import('../../components/common/notification/PanelCard.vue' /* webpackChunkName: "components/common-notification-panel-card" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchBar = () => import('../../components/common/searchBar/SearchBar.vue' /* webpackChunkName: "components/common-search-bar" */).then(c => wrapFunctional(c.default || c))
export const CommonSearchResultComponent = () => import('../../components/common/searchBar/SearchResultComponent.vue' /* webpackChunkName: "components/common-search-result-component" */).then(c => wrapFunctional(c.default || c))
export const CommonTabPrivacyTabContainer = () => import('../../components/common/tab/PrivacyTabContainer.vue' /* webpackChunkName: "components/common-tab-privacy-tab-container" */).then(c => wrapFunctional(c.default || c))
export const CommonTabContainer = () => import('../../components/common/tab/TabContainer.vue' /* webpackChunkName: "components/common-tab-container" */).then(c => wrapFunctional(c.default || c))
export const CommonWidgetFalconAd = () => import('../../components/common/widget/FalconAd.vue' /* webpackChunkName: "components/common-widget-falcon-ad" */).then(c => wrapFunctional(c.default || c))
export const CommonWidgetFalconAdAdult = () => import('../../components/common/widget/FalconAdAdult.vue' /* webpackChunkName: "components/common-widget-falcon-ad-adult" */).then(c => wrapFunctional(c.default || c))
export const CommonWidgetGamAd = () => import('../../components/common/widget/GamAd.vue' /* webpackChunkName: "components/common-widget-gam-ad" */).then(c => wrapFunctional(c.default || c))
export const MainIntroDialog = () => import('../../components/main/introDialog/index.vue' /* webpackChunkName: "components/main-intro-dialog" */).then(c => wrapFunctional(c.default || c))
export const MainContainer = () => import('../../components/main/mainContaner/MainContainer.vue' /* webpackChunkName: "components/main-container" */).then(c => wrapFunctional(c.default || c))
export const MainRightContainer = () => import('../../components/main/rightContainer/RightContainer.vue' /* webpackChunkName: "components/main-right-container" */).then(c => wrapFunctional(c.default || c))
export const V3NotificationPanel = () => import('../../components/v3/notification/Panel.vue' /* webpackChunkName: "components/v3-notification-panel" */).then(c => wrapFunctional(c.default || c))
export const V3NotificationPanelCard = () => import('../../components/v3/notification/PanelCard.vue' /* webpackChunkName: "components/v3-notification-panel-card" */).then(c => wrapFunctional(c.default || c))
export const V3SearchBar = () => import('../../components/v3/searchBar/SearchBar.vue' /* webpackChunkName: "components/v3-search-bar" */).then(c => wrapFunctional(c.default || c))
export const V3SearchResultComponent = () => import('../../components/v3/searchBar/SearchResultComponent.vue' /* webpackChunkName: "components/v3-search-result-component" */).then(c => wrapFunctional(c.default || c))
export const V3UtilsPostButton = () => import('../../components/v3/utils/PostButton.vue' /* webpackChunkName: "components/v3-utils-post-button" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleReport = () => import('../../components/child/article/lightbox/ArticleReport.vue' /* webpackChunkName: "components/child-article-report" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleReportResponse = () => import('../../components/child/article/lightbox/ArticleReportResponse.vue' /* webpackChunkName: "components/child-article-report-response" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleShare = () => import('../../components/child/article/lightbox/ArticleShare.vue' /* webpackChunkName: "components/child-article-share" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleLightboxPcardShortArticleCheckToDelete = () => import('../../components/child/article/lightbox/PcardShortArticleCheckToDelete.vue' /* webpackChunkName: "components/child-article-lightbox-pcard-short-article-check-to-delete" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleLightboxShortArticleCheckToDelete = () => import('../../components/child/article/lightbox/ShortArticleCheckToDelete.vue' /* webpackChunkName: "components/child-article-lightbox-short-article-check-to-delete" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleLightboxShortArticleReport = () => import('../../components/child/article/lightbox/ShortArticleReport.vue' /* webpackChunkName: "components/child-article-lightbox-short-article-report" */).then(c => wrapFunctional(c.default || c))
export const ChildArticleSubUrlOgReview = () => import('../../components/child/article/sub/UrlOgReview.vue' /* webpackChunkName: "components/child-article-sub-url-og-review" */).then(c => wrapFunctional(c.default || c))
export const ChildLoadingCardArticleLoadingCard = () => import('../../components/child/loading/card/ArticleLoadingCard.vue' /* webpackChunkName: "components/child-loading-card-article-loading-card" */).then(c => wrapFunctional(c.default || c))
export const ChildLoadingContainerArticleLoadingContainer = () => import('../../components/child/loading/container/ArticleLoadingContainer.vue' /* webpackChunkName: "components/child-loading-container-article-loading-container" */).then(c => wrapFunctional(c.default || c))
export const ChildLoadingContainerBloggerRankingLoadingContainer = () => import('../../components/child/loading/container/BloggerRankingLoadingContainer.vue' /* webpackChunkName: "components/child-loading-container-blogger-ranking-loading-container" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardModalUserPointIntro = () => import('../../components/child/pcard/modal/UserPointIntro.vue' /* webpackChunkName: "components/child-pcard-modal-user-point-intro" */).then(c => wrapFunctional(c.default || c))
export const ChildPcardModalUserPointRankDetail = () => import('../../components/child/pcard/modal/UserPointRankDetail.vue' /* webpackChunkName: "components/child-pcard-modal-user-point-rank-detail" */).then(c => wrapFunctional(c.default || c))
export const MainMainContanerLeftContainer = () => import('../../components/main/mainContaner/leftContainer/LeftContainer.vue' /* webpackChunkName: "components/main-main-contaner-left-container" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
